import * as React from 'react'

import { HistoryPointsRecord } from '../../types/typesMenu'

// eslint-disable-next-line prettier/prettier
interface IHistoryAddsPointsProps {
    historyAddsPoints: HistoryPointsRecord[]
}

const HistoryAddsPoints: React.FunctionComponent<IHistoryAddsPointsProps> = (props) => {
    return (
        <>
            {props.historyAddsPoints.slice(0, 15).map((record: HistoryPointsRecord, index: number) => (
                <div key={index} className='flex justify-between p-1 text-[#77787b]'>
                    <div>
                        {record.employee.fcs} +{record.addPoints} Али
                    </div>
                    <div>
                        {record.oldPoints + '->'}
                        {record.points}
                    </div>
                </div>
            ))}
        </>
    )
}

export default HistoryAddsPoints
