import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { HTTP } from '../env'
interface ApiError {
    data: {
        error: string
        message: string
        statusCode: number
    }
}

const http = new HTTP()

export const API = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: http.API,
        prepareHeaders: (headers) => {
            headers.set('Authorization', 'Bearer 1')
            return headers
        }
    }),
    endpoints: (build) => ({
        get: build.query<any, { url: string; params?: any }>({
            query: ({ url, params }) => ({
                url,
                method: 'GET',
                params
            })
        }),
        delete: build.query<any, { url: string; params?: any }>({
            query: ({ url, params }) => ({
                url,
                method: 'DELETE',
                params
            })
        }),
        post: build.mutation<any, { url: string; body: any }>({
            query: ({ url, body }) => ({
                url,
                method: 'POST',
                body
            })
        })
    })
})

export const { useGetQuery, useLazyGetQuery, useLazyDeleteQuery, usePostMutation } = API
export type { ApiError }
