import { useEffect, useState } from 'react'

import { HistoryPointsRecord } from '../../types/typesMenu'
import { useLazyGetQuery } from '../API'

export const useFetchHistoryPointsRecord = (): {
    records: HistoryPointsRecord[]
    isLoading: boolean
    error: string | null
} => {
    const [fetch, { isLoading }] = useLazyGetQuery()
    const [records, setRecords] = useState<HistoryPointsRecord[]>([])
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        let url = '/history_points_records'
        const fetchData = async () => {
            try {
                const categories = await fetch({ url })
                setRecords(categories.data.data)
            } catch (err) {
                setError('Ошибка загрузки истории начисления поинтов')
            }
        }
        fetchData()

        // Очистка
        return () => {
            setRecords([])
            setError(null)
        }
    }, [fetch])
    return { records, isLoading, error }
}
