import * as React from 'react'

import { useDispatch } from 'react-redux'
import { TECollapse } from 'tw-elements-react'

import burger_img from '../../images/burger.svg'
import button_link_img from '../../images/button_link.svg'
import { increment, decrement } from '../../store/features/isActiveMenu'

// eslint-disable-next-line prettier/prettier
export interface IUrlsMenuProps {
    isMobile: boolean
    setMobileIsActive: (mobileIsActive: boolean) => void
}

export function UrlsMenu(props: IUrlsMenuProps) {
    const links = [
        {
            text: 'Ссылка на мониторинг',
            url: 'https://main.alyans-auto.ru/admin/users'
        },
        {
            text: 'Ссылка на магазин АЛИ',
            url: 'https://alyans-auto.ru/alyans-shop/'
        },
        {
            text: 'Ссылка на чат поддержки',
            url: 'https://admin.main.alyans-auto.ru/chat'
        },
        {
            text: 'Ссылка на привязку номеров',
            url: 'https://main.alyans-auto.ru/admin/permanent-code'
        },
        {
            text: 'Ссылка на распознование номеров',
            url: 'https://main.alyans-auto.ru/admin/camera'
        }
    ]

    const dispatch = useDispatch()

    const [activeElement, setActiveElement] = React.useState('')
    const handleClick = (value: string) => {
        if (value === activeElement) {
            setActiveElement('')
            dispatch(decrement())
            if (props.isMobile) props.setMobileIsActive(false)
        } else {
            setActiveElement(value)
            dispatch(increment())
            if (props.isMobile) props.setMobileIsActive(true)
        }
    }

    return (
        <div id='accordionExample' className='bg-[#2E2E2E] text-white'>
            <div className='rounded-t-lg border'>
                <h2 id='headingOne'>
                    <button
                        // eslint-disable-next-line tailwindcss/no-custom-classname
                        className={`${props.isMobile === false ? 'px-5' : 'px-1 pr-3'} ${activeElement === 'element1' && `text-primary`} group relative flex w-full items-center border-0 py-4 text-left text-base transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none`}
                        type='button'
                        onClick={() => handleClick('element1')}
                        aria-expanded='true'
                        aria-controls='collapseOne'>
                        <div className={`${props.isMobile === false ? 'flex' : 'hidden'}`}>
                            <img src={burger_img} alt='123' />
                            {props.isMobile === false && <div className='ml-4'>Полезные ссылки</div>}
                        </div>
                        <div
                            className={`${props.isMobile === true && 'element1' === activeElement ? 'flex' : 'hidden'}`}>
                            <img src={burger_img} alt='123' />
                            <div className='ml-4'>Полезные ссылки</div>
                        </div>
                        <span
                            className={`${activeElement === 'element1' ? `-mr-1 -rotate-180` : `rotate-0`} ml-auto size-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none`}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='1.5'
                                stroke='white'
                                className='size-6'>
                                <path strokeLinecap='round' strokeLinejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' />
                            </svg>
                        </span>
                    </button>
                </h2>
                {links.map((link, index) => (
                    <div key={index} className='bg-white'>
                        <TECollapse show={activeElement === 'element1'} className='!mt-0 !rounded-b-none !shadow-none'>
                            {activeElement === 'element1' && (
                                <a
                                    target='_blank'
                                    href={link.url}
                                    className='flex justify-between border border-solid px-5 py-4 text-black transition hover:border-none hover:bg-[#E7BA80]'
                                    rel='noreferrer'>
                                    <p>{link.text}</p>
                                    <img src={button_link_img} alt='' />
                                </a>
                            )}
                        </TECollapse>
                    </div>
                ))}
            </div>
        </div>
    )
}
