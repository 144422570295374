import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../store'

interface MobileState {
    isMobile: boolean
}

const initialState: MobileState = {
    isMobile: false
}

const isMobileSlice = createSlice({
    name: 'isMobile',
    initialState,
    reducers: {
        setIsMobile: (state, action: PayloadAction<boolean>) => {
            state.isMobile = action.payload
        }
    }
})

export const { setIsMobile } = isMobileSlice.actions

export const selectIsMobile = (state: RootState) => state.isMobile.isMobile // Создайте селектор

export default isMobileSlice.reducer
