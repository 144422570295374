import React from 'react'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Main from '../pages/main'
import Tasks from '../pages/tasks'

import Route_paths from './route_paths'

// eslint-disable-next-line prettier/prettier
interface IRoutesAppProps { }

const RoutesApp: React.FC<IRoutesAppProps> = (props) => {
    const route_paths = new Route_paths()
    return (
        <>
            <Router>
                <Routes>
                    <Route path={route_paths.paths[0].path} element={<Main />} />
                    <Route path={route_paths.paths[1].path} element={<Tasks />} />
                </Routes>
            </Router>
        </>
    )
}

export default RoutesApp
