/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'

import settings_img from '../../images/Settings.svg'
import useWebSocket from '../../sockets/useWebSocket'
import { useFetchEmployees } from '../../store/requests/requestsMain'
import { useFetchHistoryPointsRecord } from '../../store/requests/requestsMenu'
import { Employee } from '../../types/typesMain'
import { HistoryPointsRecord } from '../../types/typesMenu'
import HistoryAddsPoints from '../menu/historyAddsPoints';
import { UrlsMenu } from '../menu/urlsMenu'
import UserCard from '../menu/userCard'

import '../../css/main.css'


interface MenuProps {
    isMobile: boolean
}

const Menu = (props: MenuProps) => {
    const [isMobileActive, setIsMobileActive] = useState<boolean>(false)
    useEffect(() => {
        setIsMobileActive(false)
    }, [props.isMobile])

    const [employees, setEmployees] = useState<Employee[]>([])
    const { records: employeesFetch, isLoading: isLoadingEmployees, error: errorEmployees } = useFetchEmployees()
    useEffect(() => {
        setEmployees(employeesFetch)
    }, [employeesFetch])

    const [historyAddsPoints, setHistoryAddsPoints] = useState<HistoryPointsRecord[]>([])
    const { records: historyAddsPointsFetch } = useFetchHistoryPointsRecord()
    useEffect(() => {
        setHistoryAddsPoints(historyAddsPointsFetch)
    }, [historyAddsPointsFetch])

    useWebSocket(employees, setEmployees, historyAddsPoints, setHistoryAddsPoints);

    const siteName = () => (
        <div className={`my-auto ml-5 h-full ${props.isMobile === false ? 'text-2xl' : 'text-lg'}`}>
            <div>ГК Альянс-Авто</div>
            <div>Поддержка</div>
        </div>
    )

    const userCard = (index: number, fcs: string, position: string, points: number) => {
        return (
            <div key={index} className={`bg-[#2c2c2c]`}>
                <UserCard name={fcs} position={position} points={points} isTOP={index === 0 ? true : false} />
            </div>
        )
    }

    return (
        <nav
            className={`${props.isMobile === false ? 'w-1/5 min-w-[330px]' : 'w-[42px]'} ${isMobileActive === true && 'w-1/5 min-w-[330px]'} bg-[#2E2E2E] text-white`}>
            <div className={` ${props.isMobile === false ? 'ml-3 mt-4 flex' : 'ml-[0.35rem] mt-4 flex'}`}>
                <img width={props.isMobile === false ? 60 : 30} src={settings_img} alt='setting'></img>
                {props.isMobile === false ? siteName() : isMobileActive === true && siteName()}
            </div>
            <br />
            <div className='flex justify-around'>
                <a href="/" className='w-1/2  py-3 text-center transition duration-150 ease-out hover:bg-white hover:text-black hover:ease-in'>Заявки</a>
                <a href="/tasks" className='w-1/2 py-3 text-center transition duration-150 ease-out hover:bg-white hover:text-black hover:ease-in'>Задачи</a>
            </div>
            <br />
            <div className={`${props.isMobile === true && '-mt-4'}`}>
                <UrlsMenu isMobile={props.isMobile} setMobileIsActive={setIsMobileActive} />
            </div>

            {props.isMobile === false && isLoadingEmployees && <div className='m-5 text-xl'>Загрузка...</div>}
            {props.isMobile === false && errorEmployees && <div className='m-5 text-xl'>Ошибка сервера...</div>}
            {employees && props.isMobile === false
                ? employees.map((employee: Employee, index: number) => {
                    return (
                        <div key={index} className={`bg-[#2c2c2c]`}>
                            {userCard(index, employee.fcs, employee.position, employee.bb)}
                        </div>
                    )
                })
                : employees &&
                isMobileActive === true &&
                employees.map((employee: Employee, index: number) => (
                    <div key={index} className={`bg-[#2c2c2c]`}>
                        {userCard(index, employee.fcs, employee.position, employee.bb)}
                    </div>
                ))}
            <div className='p-3'>
                <HistoryAddsPoints historyAddsPoints={historyAddsPoints} />
            </div>
        </nav>
    )
}

export default Menu
