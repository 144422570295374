import * as React from 'react'

import iconWand from '../../images/Rectangle 38.svg'
import '../../css/main.css'

// eslint-disable-next-line prettier/prettier
interface IFilterProps {
    isAll: boolean
    onToggleRequest: (toggleRequest: boolean) => void
}

const Filter: React.FC<IFilterProps> = (props) => {
    return (
        <div className='absolute ml-[-20px] mt-[-75px]'>
            <span id='button_filter' className='bg-[#b9b0b0] font-semibold text-white'>
                <div id='buttons_filters'>
                    <div className='flex'>
                        <button
                            id='button_edit'
                            onClick={() => props.onToggleRequest(true)}
                            className={`px-5 py-3 ${props.isAll === true && 'text-[#E7BA80] underline'}`}>
                            Все
                        </button>
                        <img src={iconWand} alt='stick' />
                        <img src={iconWand} alt='stick' />
                        <button
                            id='button_delete'
                            onClick={() => props.onToggleRequest(false)}
                            className={`px-5 py-3 ${props.isAll === false && 'text-[#E7BA80] underline'}`}>
                            Горящие
                        </button>
                    </div>
                </div>
            </span>
        </div>
    )
}

export default Filter
