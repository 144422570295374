import React, { ReactNode } from 'react'

interface ModalWindowCProps {
    title: string
    onCloseModal: () => void
    children: ReactNode
}

const ModalWindowC: React.FC<ModalWindowCProps> = (props) => {
    return (
        // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
        <div aria-hidden='true' className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
            <div className='relative max-h-full w-screen max-w-[750px] p-4' id='modal'>
                <div className='relative rounded-lg bg-white shadow'>
                    <div className='flex items-center justify-between rounded-t border-b p-4 pb-2'>
                        <h1 className='text-center text-2xl font-bold'>{props.title}</h1>
                        <button
                            type='button'
                            className='ms-auto inline-flex size-8 items-center justify-center rounded-lg bg-gray-200 text-sm text-black transition-colors hover:bg-red-400 hover:text-white'
                            onClick={props.onCloseModal}>
                            <svg
                                className='size-3'
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 14 14'>
                                <path
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
                                />
                            </svg>
                            <span className='sr-only'>Close modal</span>
                        </button>
                    </div>
                    <div className='p-5 pt-1'>{props.children}</div>
                </div>
            </div>
        </div>
    )
}

export default ModalWindowC
