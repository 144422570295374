import { createSlice } from '@reduxjs/toolkit'

interface State {
    value: boolean
}

const initialState: State = {
    value: false
}

export const isActiveMenuSlice = createSlice({
    name: 'isActiveMenu',
    initialState,
    reducers: {
        increment: (state) => {
            state.value = true
        },
        decrement: (state) => {
            state.value = false
        }
    }
})

export const { increment, decrement } = isActiveMenuSlice.actions
export default isActiveMenuSlice.reducer
