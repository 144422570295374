import * as React from 'react'

import { useSelector } from 'react-redux'

import { useLazyGetQuery } from '../../../store/API'
import { selectIsMobile } from '../../../store/features/isModileSlice'
import ModalWindowC from '../modalWindow'

interface IModalReportProps {
    setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalReport: React.FunctionComponent<IModalReportProps> = (props) => {
    const isMobile = useSelector(selectIsMobile)
    const [currentDate, setCurrentDate] = React.useState<string>('')
    const [weekAgoDate, setWeekAgoDate] = React.useState<string>('')

    React.useEffect(() => {
        // Получаем сегодняшнюю дату
        const today = new Date()
        const formattedToday = today.toISOString().slice(0, 10)
        setCurrentDate(formattedToday)
        // Получаем дату неделю назад
        const weekAgo = new Date()
        weekAgo.setDate(weekAgo.getDate() - 7)
        const formattedWeekAgo = weekAgo.toISOString().slice(0, 10)
        setWeekAgoDate(formattedWeekAgo)
    }, [])

    const [fetch, { isLoading }] = useLazyGetQuery()
    const [error, setError] = React.useState<string | null>(null)
    const [downloadLink, setDownloadLink] = React.useState<string | null>(null)
    const handleReport = () => {
        let url = '/reports/get_report'
        const fetchData = async () => {
            try {
                const params = {
                    end_date: currentDate + 'T18:00:00',
                    start_date: weekAgoDate + 'T18:00:01'
                }
                const categories = await fetch({ url, params })
                console.log(categories.data.link)
                setDownloadLink(categories.data.link)
            } catch (err) {
                setError('Ошибка загрузки заявок')
                console.log('Ошибка загрузки заявок:' + err)
            }
        }
        fetchData()
    }

    React.useEffect(() => {
        if (downloadLink) {
            const link = document.createElement('a')
            link.href = downloadLink
            link.download = 'report.xlsx'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }, [downloadLink])

    return (
        <ModalWindowC title='Формирование отчета' onCloseModal={() => props.setIsShowModal(false)}>
            <div className='mx-auto w-4/5'>
                <div
                    className={`${isMobile ? 'flex flex-col items-center' : 'flex justify-center'} mx-auto items-center text-lg`}>
                    <div>
                        <div className='text-center'></div>
                        <input
                            className='rounded-lg border px-2 py-1'
                            type='date'
                            id='current-date'
                            value={weekAgoDate}
                            onChange={(e) => setWeekAgoDate(e.target.value)}
                        />
                    </div>
                    <p className='font-bold'>По</p>
                    <div>
                        <div className='text-center'></div>
                        <input
                            className='ml-1 rounded-lg border px-2 py-1'
                            type='date'
                            id='week-ago-date'
                            value={currentDate}
                            onChange={(e) => setCurrentDate(e.target.value)}
                        />
                    </div>
                </div>
                <br />
                <div id='button_report' className='mx-auto  w-[70%] text-center' onClick={handleReport}>
                    {!isLoading ? <button>Сформировать отчёт</button> : <button disabled>Загрузка...</button>}
                    {error && <div className='pt-1 text-xs    text-red-500'>{error}</div>}
                </div>
                <br />
            </div>
        </ModalWindowC>
    )
}

export default ModalReport
