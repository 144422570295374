import * as React from 'react'

import coin_img from '../../images/coin.svg'
import crown_img from '../../images/crown.svg'
import user_img from '../../images/user.svg'

// eslint-disable-next-line prettier/prettier
interface IUserCardProps {
    name: string
    position: string
    points: number
    isTOP: boolean
}

const UserCard: React.FC<IUserCardProps> = (props) => {
    return (
        <div className='my-3 flex w-full justify-between px-3 py-5'>
            <div className='my-auto h-full'>
                <img src={user_img} alt='ava' />
            </div>
            <div className='my-auto ml-2 h-full w-[70%]'>
                <div className='flex'>
                    <div className='mb-1 font-semibold'>{props.name}</div>
                    {props.isTOP === true && (
                        <div className='-my-2 ml-2'>
                            <img width={30} src={crown_img} alt='' />
                        </div>
                    )}
                </div>
                <div className='text-[#838383]'>{props.position}</div>
            </div>
            <a
                href='https://alyans-auto.ru/alyans-shop/'
                target='_blank'
                rel='noreferrer'
                className='my-auto flex h-full'>
                <div
                    className={`my-auto h-full font-semibold text-[#ffffff] drop-shadow-lg ${props.isTOP === true && 'text-lg font-bold'}`}>
                    {props.points}
                </div>
                <img className='mt-1' src={coin_img} alt='points' />
            </a>
        </div>
    )
}

export default UserCard
