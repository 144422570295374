import React, { ReactNode, useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import Filter from '../components/main/filter'
import ModalDelete from '../components/modals/pages/modalDelete'
import ModalEdit from '../components/modals/pages/modalEdit'
import ModalReport from '../components/modals/pages/modalReport'
import iconDelete from '../images/delete.svg'
import iconDeleteHover from '../images/delete_hover.svg'
import iconEdit from '../images/edit.svg'
import iconEditHover from '../images/edit_hover.svg'
import iconFilter from '../images/filter.svg'
import iconFilterHover from '../images/filter_hover.svg'
import iconWand from '../images/Rectangle 38.svg'
import { selectIsMobile } from '../store/features/isModileSlice'
import { useFetchCategories, useFetchEmployees, useFetchReports } from '../store/requests/requestsMain'
import { Report } from '../types/typesMain'

// eslint-disable-next-line prettier/prettier
export interface IMainProps { }

const Main = (props: IMainProps) => {
    const isMobile = useSelector(selectIsMobile)
    const [isAll, setIsAll] = useState<boolean>(false)

    const { records: reports, isLoading: isLoadingReports, error: errorReports, deleteReport } = useFetchReports(isAll)
    const { records: employees } = useFetchEmployees()
    const { records: categories } = useFetchCategories()

    const [isShowFilter, setIsShowFilter] = useState(false)
    const [isShowModalReport, setIsShowModalReport] = useState(false)
    const [isShowModalDelete, setIsShowModalDelete] = useState<boolean>(false)
    const [isShowModalEdit, setIsShowModalEdit] = useState(false)
    const [deleteId, setDeleteId] = useState<number>(-1)
    const [reportForEdit, setReportForEdit] = useState<Report | null>(null)

    const handleShowModalDelete = (id: number) => {
        setDeleteId(id)
        setIsShowModalDelete(true)
    }

    const handleShowModalEdit = (report: Report) => {
        setIsShowModalEdit(true)
        setReportForEdit(report)
    }

    const [isHoveredArray_delete, setIsHoveredArray_delete] = useState<boolean[]>([])
    const handleMouseEnter_delete = (index: number) => {
        const newIsHoveredArray = [...isHoveredArray_delete]
        newIsHoveredArray[index] = true
        setIsHoveredArray_delete(newIsHoveredArray)
    }
    const handleMouseLeave_delete = (index: number) => {
        const newIsHoveredArray = [...isHoveredArray_delete]
        newIsHoveredArray[index] = false
        setIsHoveredArray_delete(newIsHoveredArray)
    }

    const [isHoveredArray_edit, setIsHoveredArray_edit] = useState<boolean[]>([])
    const handleMouseEnter_edit = (index: number) => {
        const newIsHoveredArray = [...isHoveredArray_edit]
        newIsHoveredArray[index] = true
        setIsHoveredArray_edit(newIsHoveredArray)
    }
    const handleMouseLeave_edit = (index: number) => {
        const newIsHoveredArray = [...isHoveredArray_edit]
        newIsHoveredArray[index] = false
        setIsHoveredArray_edit(newIsHoveredArray)
    }

    const [isHoveredArray_filter, setIsHoveredArray_filter] = useState<boolean[]>([])
    const handleMouseEnter_filter = (index: number) => {
        const newIsHoveredArray = [...isHoveredArray_filter]
        newIsHoveredArray[index] = true
        setIsHoveredArray_filter(newIsHoveredArray)
    }
    const handleMouseLeave_filter = (index: number) => {
        const newIsHoveredArray = [...isHoveredArray_filter]
        newIsHoveredArray[index] = false
        setIsHoveredArray_filter(newIsHoveredArray)
    }

    const handleOutsideClick = (event: any) => {
        if (
            !event.target.closest('#filters') &&
            !event.target.closest('#button_filters') &&
            !event.target.closest('#button_delete') &&
            !event.target.closest('#button_edit') &&
            !event.target.closest('#button_report') &&
            !event.target.closest('#modal') &&
            !event.target.closest('#buttons_filters')
        ) {
            setIsShowFilter(false)
            setIsShowModalReport(false)
            setIsShowModalDelete(false)
            setIsShowModalEdit(false)
        }
    }

    useEffect(() => {
        // Добавляем обработчик событий
        document.addEventListener('click', handleOutsideClick)

        // Удаляем обработчик при анмаунте компонента
        return () => {
            document.removeEventListener('click', handleOutsideClick)
        }
    }, [])

    const onToggleRequest = (toggleRequest: boolean) => {
        setIsAll(toggleRequest)
        setIsShowFilter(false)
    }

    const mobileTD = (id: number, id2: number, date: ReactNode, reportStr: string, report: Report) => {
        return (
            <>
                <tr className='text-center'>
                    <td>
                        <div className='flex items-center justify-around'>
                            <div className='text-base font-semibold'>
                                {id} ({id2})
                            </div>
                            <div>{date}</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='my-2 text-center'>{reportStr}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='flex items-center justify-between'>
                            <div></div>
                            <div>
                                <span id='button_edit-delete'>
                                    <button
                                        id='button_edit'
                                        className='px-4'
                                        onMouseEnter={() => handleMouseEnter_edit(id)}
                                        onMouseLeave={() => handleMouseLeave_edit(id)}
                                        onClick={() => handleShowModalEdit(report)}>
                                        <img src={isHoveredArray_edit[id] ? iconEditHover : iconEdit} alt='edit' />
                                    </button>
                                    <img src={iconWand} alt='stick' />
                                    <button
                                        id='button_delete'
                                        className='px-4'
                                        onMouseEnter={() => handleMouseEnter_delete(id)}
                                        onMouseLeave={() => handleMouseLeave_delete(id)}
                                        onClick={() => handleShowModalDelete(id)}>
                                        <img
                                            src={isHoveredArray_delete[id] ? iconDeleteHover : iconDelete}
                                            alt='delete'
                                        />
                                    </button>
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
            </>
        )
    }

    return (
        <section>
            {isShowModalEdit && (
                <ModalEdit
                    isAll={isAll}
                    setIsShowModalEdit={setIsShowModalEdit}
                    allEmployees={employees}
                    allCategories={categories}
                    deleteReport={deleteReport}
                    report={reportForEdit}
                />
            )}
            {isShowModalDelete && (
                <ModalDelete
                    setIsShowModalDelete={setIsShowModalDelete}
                    deleteReport={deleteReport}
                    deleteId={deleteId}
                />
            )}
            {isShowModalReport && <ModalReport setIsShowModal={setIsShowModalReport} />}

            <h1 className='text-center text-2xl font-bold'>Заявки для поддержки</h1>
            <div className='flex justify-between'>
                <div></div>
                <div id='button_report' onClick={() => setIsShowModalReport(true)}>
                    <button>Отчет</button>
                </div>
            </div>
            <div className='mb-2 flex'>
                <div
                    onMouseEnter={() => handleMouseEnter_filter(0)}
                    onMouseLeave={() => handleMouseLeave_filter(0)}
                    onMouseOver={() => setIsShowFilter(true)}>
                    <div id='filters' className='flex'>
                        <div id='filter'>
                            <img src={isHoveredArray_filter[0] ? iconFilterHover : iconFilter} alt='фильтры' />
                        </div>
                        <div
                            id='text_filter'
                            className={`${isHoveredArray_filter[0] ? 'text-[#2e2e2e]' : 'text-[#757575]'}`}>
                            Фильтры
                        </div>
                    </div>
                    <div onMouseOut={() => setIsShowFilter(false)} className={`${!isShowFilter && 'hidden'}`}>
                        <Filter isAll={isAll} onToggleRequest={onToggleRequest} />
                    </div>
                </div>
            </div>
            <div className='min-w-full overflow-x-auto'>
                <table className='w-full bg-white'>
                    <thead>
                        <tr>
                            {isMobile === false && (
                                <>
                                    <th>id</th>
                                    <th>Дата</th>
                                </>
                            )}
                            <th>Обращение</th>
                            {isMobile === false && (
                                <>
                                    <th>Номер телефона</th>
                                    <th>id телеграмма</th>
                                    <th>Категория</th>
                                    <th>Решение</th>
                                    <th>Сотрудник</th>
                                    <th id='th_button'>Кнопки</th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {reports &&
                            reports.length !== 0 &&
                            (isAll ? [...reports].reverse() : reports).map((report: Report, index: number) => {
                                const parts = report.CreatedAt?.toString()?.split('T')
                                return (
                                    <tr
                                        key={report.ID}
                                        className={`${isAll && report.decision === '' && 'bg-red-300'} ${index % 2 !== 0 ? 'bg-[#f2f1ef] max-sm:text-sm' : 'max-sm:text-sm'} ${isMobile === true && 'p-3'}`}>
                                        {isMobile === false ? (
                                            <>
                                                <td>
                                                    {isAll ? reports.length - index : index + 1} ({report.ID})
                                                </td>
                                                <td>
                                                    <div>
                                                        <div className='text-nowrap'>{parts && parts[0]}</div>
                                                        <br />
                                                        <div className='text-nowrap'>
                                                            {parts && parts[1].split('.')[0]}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{report.report}</td>
                                                <td>{report.reports_phone}</td>
                                                <td>{report.telegram_id}</td>
                                                <td>{report.category.category}</td>
                                                <td>{report.decision}</td>
                                                <td>{report.employee.fcs}</td>
                                                <td>
                                                    <div>
                                                        <span id='button_edit-delete'>
                                                            <button
                                                                id='button_edit'
                                                                onMouseEnter={() => handleMouseEnter_edit(index)}
                                                                onMouseLeave={() => handleMouseLeave_edit(index)}
                                                                onClick={() => {
                                                                    handleShowModalEdit(report)
                                                                }}
                                                                disabled={isAll}>
                                                                <img
                                                                    src={
                                                                        isHoveredArray_edit[index]
                                                                            ? iconEditHover
                                                                            : iconEdit
                                                                    }
                                                                    alt='edit'
                                                                />
                                                            </button>
                                                            <img src={iconWand} alt='stick' />
                                                            <button
                                                                id='button_delete'
                                                                onMouseEnter={() => handleMouseEnter_delete(index)}
                                                                onMouseLeave={() => handleMouseLeave_delete(index)}
                                                                onClick={() => handleShowModalDelete(report.ID)}>
                                                                <img
                                                                    src={
                                                                        isHoveredArray_delete[index]
                                                                            ? iconDeleteHover
                                                                            : iconDelete
                                                                    }
                                                                    alt='delete'
                                                                />
                                                            </button>
                                                        </span>
                                                    </div>
                                                </td>
                                            </>
                                        ) : (
                                            mobileTD(
                                                reports.length - index,
                                                report.ID,
                                                <div>
                                                    <div className='text-nowrap'>{parts && parts[0]}</div>
                                                    <br />
                                                    <div className='text-nowrap'>{parts && parts[1].split('.')[0]}</div>
                                                </div>,
                                                report.report,
                                                report
                                            )
                                        )}
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
            {isLoadingReports && (
                <div className='mt-2 w-full text-nowrap text-center text-xl font-semibold text-[#000f2c]'>
                    Загрузка...
                </div>
            )}
            {errorReports && (
                <div className='mt-2 w-full text-nowrap text-center text-xl font-medium text-[#000f2c]'>
                    Заявок не найдено
                </div>
            )}
        </section>
    )
}

export default Main
