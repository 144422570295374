import { Dispatch, SetStateAction, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { HTTP } from '../env'
import { Employee } from '../types/typesMain'
import { HistoryPointsRecord } from '../types/typesMenu'

interface DefaultSock {
    name: string
}
interface UserLocalPoints extends DefaultSock {
    tg_id: string
    points: number
}
const http = new HTTP()

const useWebSocket = (
    employees: Employee[],
    setEmployees: Dispatch<SetStateAction<Employee[]>>,
    historyAddsPoints: HistoryPointsRecord[],
    setHistoryAddsPoints: Dispatch<SetStateAction<HistoryPointsRecord[]>>
) => {
    const dispatch = useDispatch()

    useEffect(() => {
        const websocket = new WebSocket(http.SOCKET)
        websocket.onmessage = (event) => {
            const data = JSON.parse(event.data)
            console.log(data)
            let typedData: any
            // FIXME: Сделать изменение в реальном времени истории
            if (data.name === 'syncHistoryPoints') {
                setHistoryAddsPoints((prevHistory: HistoryPointsRecord[]) => [...prevHistory, data.historyPointsRecord])
            } else if (data.name === 'syncPoints') {
                typedData as UserLocalPoints
                typedData = data
                setEmployees(
                    employees.map((employee: Employee) => {
                        if (employee.tg_id === typedData.tg_id) {
                            return { ...employee, bb: typedData.points }
                        } else {
                            return employee
                        }
                    })
                )
            }
        }

        // Очистка при размонтировании компонента
        return () => {
            websocket.close()
        }
    }, [employees, setEmployees, dispatch, historyAddsPoints, setHistoryAddsPoints])
}

export default useWebSocket
