import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { API } from './API'
import isActiveMenuReducer from './features/isActiveMenu'
import isMobileReducer from './features/isModileSlice'

const store = configureStore({
    reducer: {
        [API.reducerPath]: API.reducer,
        isActiveMenuSlice: isActiveMenuReducer,
        isMobile: isMobileReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(API.middleware)
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
