import React from 'react'

// eslint-disable-next-line import/order
import ReactDOM from 'react-dom/client'

import './css/index.css'
import './css/reset.css'

import { Provider } from 'react-redux'

import App from './App'
import store from './store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Provider store={store}>
        <App />
    </Provider>
)
