import { useEffect, useState } from 'react'

import { Report, Employee, Category } from '../../types/typesMain'
import { useLazyGetQuery } from '../API'

export const useFetchReports = (
    isAll: boolean
): {
    records: Report[]
    isLoading: boolean
    error: string | null
    deleteReport: (id: number) => void
} => {
    const [fetchRequests, { isLoading }] = useLazyGetQuery()
    const [records, setRecords] = useState<Report[]>([])
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        let url = '/reports'
        if (!isAll) {
            url = '/reports?filter[decision]=null'
        }
        const fetchData = async () => {
            try {
                const response = await fetchRequests({ url })
                setRecords(response.data.data)
            } catch (err) {
                setError('Ошибка загрузки заявок')
            }
        }
        fetchData()

        // Очистка
        return () => {
            setRecords([])
            setError(null)
        }
    }, [fetchRequests, isAll])

    const deleteReport = (id: number) => {
        setRecords((prevRecords) => prevRecords.filter((record: Report) => record.ID !== id))
    }

    return { records, isLoading, error, deleteReport }
}

export const useFetchEmployees = (): { records: Employee[]; isLoading: boolean; error: string | null } => {
    const [fetch, { isLoading }] = useLazyGetQuery()
    const [records, setRecords] = useState<Employee[]>([])
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        let url = '/employees'
        const fetchData = async () => {
            try {
                const categories = await fetch({ url })
                setRecords(categories.data.data)
            } catch (err) {
                setError('Ошибка загрузки заявок')
            }
        }
        fetchData()

        // Очистка
        return () => {
            setRecords([])
            setError(null)
        }
    }, [fetch])
    return { records, isLoading, error }
}

export const useFetchCategories = (): { records: Category[]; isLoading: boolean; error: string | null } => {
    const [fetch, { isLoading }] = useLazyGetQuery()
    const [records, setRecords] = useState<Category[]>([])
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        let url = '/categories'
        const fetchData = async () => {
            try {
                const categories = await fetch({ url })
                setRecords(categories.data.data)
            } catch (err) {
                setError('Ошибка загрузки заявок')
            }
        }
        fetchData()

        // Очистка
        return () => {
            setRecords([])
            setError(null)
        }
    }, [fetch])
    return { records, isLoading, error }
}
