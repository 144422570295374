import React from 'react'

// eslint-disable-next-line prettier/prettier
interface ITasksProps { }

const Tasks: React.FC<ITasksProps> = (props) => {
    return (
        <div>
            <h1 className='text-center text-2xl font-bold'>Задачи сотрудников</h1>
            <div className='flex justify-between'>
                <div></div>
                <div id='button_report'>
                    <button>Поставить задачу</button>
                </div>
            </div>
            <br />
            <table className='w-full bg-white'>
                <thead>
                    <tr id='tasks'>
                        <th>id</th>
                        <th>Дата</th>
                        <th>Задание</th>
                        <th>Решение</th>
                        <th>Сотрудник</th>
                        <th id='th_button'>Кнопки</th>
                    </tr>
                </thead>
            </table>
        </div>
    )
}

export default Tasks
