import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import Menu from './components/menu/menu'
import Main from './pages/main'
import RoutesApp from './routes/routes'
import { selectIsMobile, setIsMobile } from './store/features/isModileSlice'

export default function App() {
    const dispatch = useDispatch()
    const isMobile = useSelector(selectIsMobile)
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1200) dispatch(setIsMobile(false))
            else dispatch(setIsMobile(true))
        }
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile])

    const isActiveMenu = useSelector((state: any) => state.isActiveMenuSlice.value)

    return (
        <div className='flex min-h-screen bg-[#F3F0F0]'>
            <Menu isMobile={isMobile} />
            <div className={`w-full ${isMobile && 'mt-5'}`}>
                {/* eslint-disable-next-line tailwindcss/migration-from-tailwind-2 */}
                {isActiveMenu && isMobile && <div className='absolute h-screen w-full bg-black bg-opacity-70' />}
                <div className={`${isMobile ? '' : 'px-9 py-6'}`}>
                    <RoutesApp />
                </div>
            </div>
        </div>
    )
}
