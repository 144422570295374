import * as React from 'react'

import { useLazyDeleteQuery } from '../../../store/API'
import ModalWindowC from '../modalWindow'

interface IModalDeleteProps {
    setIsShowModalDelete: React.Dispatch<React.SetStateAction<boolean>>
    deleteReport: (id: number) => void
    deleteId: number | null
}

const ModalDelete: React.FunctionComponent<IModalDeleteProps> = (props) => {
    const [deleteReport] = useLazyDeleteQuery()
    const handleDeleteRecord = async () => {
        await deleteReport({
            url: `/reports/${props.deleteId}`
        })
        if (props.deleteId) props.deleteReport(props.deleteId)
        props.setIsShowModalDelete(false)
    }

    return (
        <ModalWindowC title='Удаление' onCloseModal={() => props.setIsShowModalDelete(false)}>
            <div className='text-center'>
                <svg
                    className='mx-auto mb-4 size-12 text-red-400 dark:text-gray-200'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 20'>
                    <path
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
                    />
                </svg>
                <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
                    Удалить эту запись безвозвратно?
                </h3>
                <button
                    data-modal-hide='popup-modal'
                    type='button'
                    onClick={() => handleDeleteRecord()}
                    className='inline-flex items-center rounded-lg bg-red-400 px-5 py-2.5 text-center text-sm font-medium text-white transition-colors hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800'>
                    Да, я уверен
                </button>
                <button
                    data-modal-hide='popup-modal'
                    type='button'
                    onClick={() => props.setIsShowModalDelete(false)}
                    className='ms-3 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700'>
                    Нет, отмена
                </button>
            </div>
        </ModalWindowC>
    )
}

export default ModalDelete
function useLazeDeleteQuery(): [any] {
    throw new Error('Function not implemented.')
}
