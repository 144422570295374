import * as React from 'react'

import { useSelector } from 'react-redux'

import { usePostMutation } from '../../../store/API'
import { selectIsMobile } from '../../../store/features/isModileSlice'
import { Category, Employee, Report, ReportOutput } from '../../../types/typesMain'
import ModalWindowC from '../modalWindow'

interface IModalEditProps {
    isAll: boolean
    setIsShowModalEdit: React.Dispatch<React.SetStateAction<boolean>>
    allEmployees: Employee[]
    allCategories: Category[]
    deleteReport: (id: number) => void
    report: Report | null
}

const ModalEdit: React.FunctionComponent<IModalEditProps> = (props) => {
    const isMobile = useSelector(selectIsMobile)
    const [isSuccessEdit, setIsSuccessEdit] = React.useState(false)

    const [fetchReportsUpdate] = usePostMutation()
    const [editData, setEditData] = React.useState<ReportOutput>({
        ID: props.report?.ID ?? 0,
        report: props.report?.report ?? '',
        reports_phone: props.report?.reports_phone ?? '',
        telegram_id: props.report?.telegram_id ?? '',
        decision: props.report?.decision ?? '',
        category: props.report?.category.ID ?? 0,
        employee: props.report?.employee.ID ?? 0
    })

    const [selectedIdCategory, setSelectedIdCategory] = React.useState<number>(0)
    const [selectedIdEmployee, setSelectedIdEmployee] = React.useState<number>(0)

    const handleSelectChangeCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedIdCategory(Number(event.target.value))
    }

    const handleSelectChangeEmployee = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedIdEmployee(Number(event.target.value))
    }

    const handleUpdateRecord = () => {
        // FIXME: Нужно сделать проверку на пустые поля, и вообще оформить валидацию. Также обратную связь с бэком при непролхзождении запроса.
        const fetchData = async () => {
            await fetchReportsUpdate({
                url: '/reports',
                body: {
                    report: editData?.report,
                    reports_phone: editData?.reports_phone,
                    telegram_id: editData.telegram_id,
                    decision: editData?.decision,
                    category_id: selectedIdCategory,
                    employee_id: selectedIdEmployee
                }
            })
        }
        fetchData()
        setIsSuccessEdit(true)
        setTimeout(() => {
            setIsSuccessEdit(false)
            props.setIsShowModalEdit(false)
            if (editData) props.deleteReport(editData.ID)
        }, 1000)
    }

    const textarea = (id: string, title: string, value: string = '') => (
        <div className='mx-auto'>
            <p className='mb-1 text-center text-lg'>{title}</p>
            <textarea
                id={id}
                rows={9}
                disabled={props.isAll === true && isMobile === true}
                placeholder='Введите текст здесь...'
                value={value}
                onChange={handleEditChange}
                className='w-80 max-w-full rounded-xl border-2 border-solid border-[#B5ABAB] bg-[#FBF9F9] p-3'
            />
        </div>
    )

    const handleEditChange = (event: any) => {
        const { id, value } = event.target
        setEditData({ ...editData, [id]: value })
    }

    const input = (
        id: string,
        title: string,
        type: string,
        value: string = '',
        propertyName?: string,
        allCases?: any[]
    ) => {
        return (
            <div className={`${isMobile === false && 'w-1/4'} mx-1 rounded-lg bg-[#D9D9D9] p-1`}>
                <p className='mb-1 text-center'>{title}</p>
                {type === 'select' ? (
                    <select
                        id={id}
                        className='w-full rounded-lg border px-2'
                        onChange={id === 'category' ? handleSelectChangeCategory : handleSelectChangeEmployee}
                        disabled={props.isAll === true && isMobile === true}>
                        <option key={0}>Не выбрано</option>
                        {allCases &&
                            propertyName &&
                            allCases.map((item: any, index: number) => (
                                <option key={index + 1} id={item.ID} value={item.ID}>
                                    {item[propertyName]}
                                </option>
                            ))}
                    </select>
                ) : (
                    type === 'text' && (
                        <input
                            id={id}
                            value={value}
                            type={type}
                            disabled={props.isAll === true && isMobile === true}
                            onChange={handleEditChange}
                            className='w-full rounded-lg border px-2 py-1 text-center'
                        />
                    )
                )}
            </div>
        )
    }

    return (
        <ModalWindowC title='Редактирование' onCloseModal={() => props.setIsShowModalEdit(false)}>
            <div className='p-2'>
                <div
                    className={`flex ${isMobile === false ? 'justify-around' : 'flex-col justify-center '} mx-auto w-full`}>
                    {textarea('report', 'Обращение', editData?.report)}
                    {textarea('decision', 'Решение', editData?.decision)}
                </div>
                <br />
                {isMobile === false ? (
                    <div className='flex px-3'>
                        {input('reports_phone', 'Телефон', 'text', editData?.reports_phone)}
                        {input('telegram_id', 'ID телеграмм', 'text', editData?.telegram_id)}
                        {input('category', 'Категория', 'select', '', 'category', props.allCategories)}
                        {input('employee', 'Сотрудник', 'select', '', 'fcs', props.allEmployees)}
                    </div>
                ) : (
                    <div className='grid grid-cols-2 gap-4 px-3'>
                        <div className='flex flex-col'>
                            {input('phone', 'Телефон', 'text', editData?.reports_phone)}
                        </div>
                        <div className='flex flex-col'>
                            {input('tg_id', 'ID телеграмм', 'text', editData?.telegram_id)}
                        </div>
                        <div className='flex flex-col'>
                            {input('category', 'Категория', 'select', '', 'category', props.allCategories)}
                        </div>
                        <div className='flex flex-col'>
                            {input('employee', 'Сотрудник', 'select', '', 'fcs', props.allEmployees)}
                        </div>
                    </div>
                )}

                <br />
                <div id='button_report' className='mx-auto w-[45%]' onClick={handleUpdateRecord}>
                    <button className='size-full' disabled={props.isAll === true && isMobile === true}>
                        Записать
                    </button>
                    {isSuccessEdit && <div className='text-green-800'>Успешная запись!</div>}
                </div>
            </div>
        </ModalWindowC>
    )
}

export default ModalEdit
